// const { localeSubpaths } = require('next/config').default().publicRuntimeConfig
const NextI18Next = require('next-i18next').default

module.exports = new NextI18Next({
  defaultLanguage: 'no',
  otherLanguages: ['no'],
  fallbackLng: ['no'],
  load: 'languageOnly',
  defaultNS: ['common'],
  browserLanguageDetection: false,
  errorStackTraceLimit: 5,
  localePath: 'public/locales',
})
